import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {SvgContainer, Text, ResultImage} from '..';

import {ResultProps} from './result.props';

export function Result(props: ResultProps) {
  const {
    extra,
    image,
    maxWidth = 'xs',
    status,
    subTitle,
    title,
    subTitleTx,
    titleTx,
  } = props;
  return (
    <Container maxWidth={maxWidth}>
      <Box sx={{textAlign: 'center', px: 3, py: 8}}>
        <SvgContainer>
          <ResultImage customImage={image} status={status} />
        </SvgContainer>
        <Text gutterBottom variant="h5" tx={titleTx}>
          {title}
        </Text>
        {(subTitle || subTitleTx) && (
          <Text variant="body2" tx={subTitleTx}>
            {subTitle}
          </Text>
        )}
        {extra && <Box sx={{mt: 4, textAlign: 'center'}}>{extra}</Box>}
      </Box>
    </Container>
  );
}
