import {DividerProps} from './divider.props';

export function Divider(props: DividerProps) {
  const {divHeight} = props;
  return (
    <div
      style={{
        width: '100%',
        height: divHeight,
        backgroundImage: `linear-gradient(to right, rgba(155, 203, 235, 0), rgba(155, 203, 235, 0.5), rgba(155, 203, 235, 0.7), rgba(0, 0, 0, 0.2), rgba(155, 203, 235, 0))`,
      }}
    />
  );
}
