import Alert, {AlertColor} from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import React, {createContext, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../components';

interface SnackbarContextInterface {
  error: (newMessage: string) => void;
  success: (newMessage: string) => void;
}

export const SnackbarContext = createContext({} as SnackbarContextInterface);

type SnackbarProviderProps = {
  children: React.ReactNode;
};

const SnackbarProvider = ({children}: SnackbarProviderProps) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [severity, setSeverity] = useState<AlertColor | undefined>(undefined);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const error = (newMessage: string) => {
    setTitle(t('common.snackbar.error'));
    setMessage(newMessage);
    setSeverity('error');
    setOpen(true);
  };

  const success = (newMessage: string) => {
    setTitle(t('common.snackbar.success'));
    setMessage(newMessage);
    setSeverity('success');
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{error, success}}>
      {children}
      <Snackbar
        key={message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          <AlertTitle>{title}</AlertTitle>
          <Text variant="body1" tx={message} />
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export default SnackbarProvider;
