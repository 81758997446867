import Box from '@mui/material/Box';
import {Text} from '..';
import {ReactComponent as LocationSvg} from '../../assets/location.svg';
import {ReactComponent as DownArrowSvg} from '../../assets/chevron-down.svg';

export function SelectLocation() {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
        padding: '10px 20px',
        borderRadius: '24px',
        boxShadow: '5px 5px 5px #00000005',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
          <LocationSvg />
        </Box>
        <Text
          variant="body1"
          sx={{
            color: '#002C58',
            fontWeight: 'fontWeightSemiBold',
            userSelect: 'none',
          }}
        >
          All Locations
        </Text>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', ml: 2}}>
        <DownArrowSvg />
      </Box>
    </Box>
  );
}
