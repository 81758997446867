import {Instance, SnapshotOut, types, applySnapshot} from 'mobx-state-tree';

import {DashboardModel} from '../dashboard/dashboard.store';

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model('RootStore').props({
  dashboard: types.optional(DashboardModel, {} as any),
})
  .actions(self => ({
    resetStore() {
      applySnapshot(self, {});
    },
    forceLogout() {
      this.resetStore();
    }
  }));

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
