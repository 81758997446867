import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import {useState} from 'react';
import {ReactComponent as ProfileSvg} from '../../assets/profile.svg';

export function Profile() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{backgroundColor: '#183B4E26', borderRadius: '12px'}}>
      <IconButton
        id="profile-button"
        aria-controls="profile-popover"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : 'false'}
        aria-label="show profile"
        color="inherit"
      >
        <ProfileSvg />
      </IconButton>
      <Popover
        id="profile-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{width: 360}}></Box>
      </Popover>
    </Box>
  );
}
