import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import {Logo, Text} from '..';
import {
  drawerCollapsedWidth,
  drawerWidth,
  drawerItems,
} from '../../config/layout';

import {DrawerProps} from './drawer.props';

export function AdminDrawer(props: DrawerProps) {
  const {collapsed, mobileOpen, onDrawerToggle} = props;

  const width = collapsed ? drawerCollapsedWidth : drawerWidth;

  const drawer = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        padding: '25px',
        backgroundColor: '#FAFCFE',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          backgroundColor: '#183B4E26',
          borderRadius: '30px',
        }}
      >
        <Logo
          sx={{
            display: 'flex',
            py: 5,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          size={75}
        />
        <List component="nav" sx={{p: 0}}>
          {drawerItems.map(item => (
            <ListItemButton key={item.key} dense>
              <ListItemAvatar>
                <Avatar sx={{color: 'inherit', bgcolor: 'transparent'}}>
                  <item.icon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{
                  display: collapsed ? 'none' : 'block',
                  color: '#002C58',
                }}
              >
                <Text
                  variant="body1"
                  sx={{
                    color: '#002C58',
                    fontWeight: item.active
                      ? 'fontWeightSemiBold'
                      : 'fontWeightMedium',
                  }}
                  tx={item.name}
                />
              </ListItemText>
              {item.active && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#FAFCFE',
                    width: '20px',
                    height: '20px',
                    borderRadius: '5px',
                    transform: 'rotate(45deg)',
                    position: 'absolute',
                    left: '-12px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#002C58',
                      width: '12px',
                      height: '12px',
                      borderRadius: '4px',
                    }}
                  />
                </div>
              )}
            </ListItemButton>
          ))}
        </List>
        <Box sx={{flexGrow: 1}} />
        <Box sx={{display: 'flex', flexDirection: 'column', p: 3}}>
          <Text
            variant="caption"
            sx={{color: '#002C58', fontWeight: 'fontWeightMedium'}}
            tx={'admin.drawer.copyright.text1'}
          />
          <Text
            variant="caption"
            sx={{color: '#002C58'}}
            tx={'admin.drawer.copyright.text2'}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      aria-label="Admin drawer"
      component="nav"
      sx={{
        width: {lg: width},
        flexShrink: {lg: 0},
      }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={onDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {xs: 'block', lg: 'none'},
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: width,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        open
        sx={{
          display: {xs: 'none', lg: 'block'},
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: width,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
