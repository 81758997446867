import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import {Text} from '..';
import {ReactComponent as EllipseSvg} from '../../assets/ellipse.svg';
import {useSettings} from '../../contexts/SettingsProvider';

import {ToolBarProps} from './toolBar.props';

export function AdminToolbar(props: ToolBarProps) {
  const {children, title} = props;
  const {toggleDrawer} = useSettings();

  return (
    <Toolbar sx={{pr: 3, pl: {xs: 3, lg: 0}}}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer}
        sx={{
          display: {lg: 'none'},
          marginRight: 2,
        }}
      >
        <MenuIcon style={{color: '#000000'}} />
      </IconButton>
      <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
        <Text
          variant="h3"
          sx={{color: '#000000', fontWeight: 'fontWeightMedium'}}
          text={title}
        />
        <EllipseSvg style={{marginLeft: '10px', marginRight: '10px'}} />
        <Text
          variant="h3"
          sx={{color: '#000000', fontWeight: 'fontWeightMedium'}}
          tx={'dashboard.tab.overview'}
        />
      </Box>
      {children}
    </Toolbar>
  );
}
