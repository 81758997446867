import Box from '@mui/material/Box';
import {ReactComponent as ErrorSvg} from '../../assets/error.svg';
import {ReactComponent as SuccessSvg} from '../../assets/success.svg';

import {ResultImageProps} from './resultImage.props';

export function ResultImage(props: ResultImageProps) {
  const {customImage, status} = props;
  let image = customImage;

  if (!image) {
    if (status === 'error') {
      image = <ErrorSvg />;
    } else if (status === 'success') {
      image = <SuccessSvg />;
    }
  }

  return image ? <Box marginBottom={3}>{image}</Box> : null;
}
