import {useState} from 'react';
import Box from '@mui/material/Box';
import {Text} from '..';

import {TabsProps} from './tabs.props';

export function Tabs(props: TabsProps) {
  const {data} = props;
  const [activeTab, setActiveTab] = useState(data[0].id);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          {data.map(tabItem => (
            <Box
              key={tabItem.id}
              sx={{
                position: 'relative',
                display: 'flex',
                mr: 5,
                '&:hover': {
                  cursor: activeTab === tabItem.id ? 'default' : 'pointer',
                },
                pb: '14px',
              }}
              onClick={() => setActiveTab(tabItem.id)}
            >
              <Text
                variant="h5"
                sx={{
                  color: activeTab === tabItem.id ? '#002C58' : '#002C5830',
                  userSelect: 'none',
                }}
                tx={tabItem.name}
              />
              {activeTab === tabItem.id && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '50%',
                    height: '3px',
                    borderRadius: '3px',
                    backgroundColor: '#002C58',
                    zIndex: 999,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '1px',
            backgroundImage: `linear-gradient(to right, rgba(155, 203, 235, 0.5), rgba(155, 203, 235, 0.5), rgba(155, 203, 235, 0.7), rgba(0, 0, 0, 0.2), rgba(155, 203, 235, 0))`,
          }}
        />
      </Box>
    </Box>
  );
}
