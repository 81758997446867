import {lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

// Core
const NotFound = lazy(() => import('./pages/notFound'));
const Layout = lazy(() => import('./pages/layout'));
const Dashboard = lazy(() => import('./pages/dashboard'));

const AppRoutes = () => (
  <Routes>
    <Route path="" element={<Layout />} />
    <Route path="/" element={<Dashboard />} />
    <Route path="404" element={<NotFound />} />
    <Route
      path="*"
      element={<Navigate to={`/${process.env.PUBLIC_URL}/404`} replace />}
    />
  </Routes>
);

export default AppRoutes;
