import {AxiosInstance} from 'axios';
import {
  GetStatusRetailerCountParams,
  GetAnonymousUsersCountParams,
  GetReuseCountParams,
} from './dashboard.type';

export interface DashboardApiInterface {
  setup(instance: AxiosInstance): void;
  getStatusRetailerCount(params: GetStatusRetailerCountParams): Promise<any>;
  getAnonymousUsersCount(params: GetAnonymousUsersCountParams): Promise<any>;
  getReuseCount(params: GetReuseCountParams): Promise<any>;
}

/**
 * Dashboard API.
 */
export class DashboardApi implements DashboardApiInterface {
  private instance?: AxiosInstance;

  setup(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getStatusRetailerCount(
    params: GetStatusRetailerCountParams,
  ): Promise<any> {
    if (this.instance) {
      const response = await this.instance.get('/Bags/Tag', {params});
      return response.data;
    }
    return null;
  }

  async getAnonymousUsersCount(
    params: GetAnonymousUsersCountParams,
  ): Promise<any> {
    if (this.instance) {
      const response = await this.instance.get('/Bags/anonymoususers', {
        params,
      });
      return response.data;
    }
    return null;
  }

  async getReuseCount(params: GetReuseCountParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.get('/Bags/UserItemMapping', {
        params,
      });
      return response.data;
    }
    return null;
  }
}
