import {flow, Instance, SnapshotOut, types} from 'mobx-state-tree';

import {withEnvironment} from '../extensions/with-environment';

const GoatoteModel = types.model({
  inventoryCount: types.maybe(types.number),
  borrowedCount: types.maybe(types.number),
  registeredCount: types.maybe(types.number),
  reusedCount: types.maybe(types.number),
  returnedCount: types.maybe(types.number),
  cbReusesCount: types.maybe(types.number),
  anonymousCount: types.maybe(types.number),
});
const MosaicModel = types.model({
  downloadsCount: types.maybe(types.number),
  usersCount: types.maybe(types.number),
  pointsCount: types.maybe(types.number),
  redeemedCount: types.maybe(types.number),
  bwPlayedCount: types.maybe(types.number),
  ordersCount: types.maybe(types.number),
  ticketsCount: types.maybe(types.number),
});

/**
 * Dashboard model.
 */
export const DashboardModel = types
  .model('Dashboard')
  .props({
    goatote: types.optional(GoatoteModel, {}),
    mosaic: types.optional(MosaicModel, {}),
  })
  .extend(withEnvironment)
  .actions(self => ({
    /* eslint-disable no-param-reassign */
    updateGoatote: (props: SnapshotOut<typeof GoatoteModel>) => {
      if (props.inventoryCount) {
        self.goatote.inventoryCount = props.inventoryCount;
      }
      if (props.borrowedCount) {
        self.goatote.borrowedCount = props.borrowedCount;
      }
      if (props.registeredCount) {
        self.goatote.registeredCount = props.registeredCount;
      }
      if (props.reusedCount) {
        self.goatote.reusedCount = props.reusedCount;
      }
      if (props.returnedCount) {
        self.goatote.returnedCount = props.returnedCount;
      }
      if (props.cbReusesCount) {
        self.goatote.cbReusesCount = props.cbReusesCount;
      }
      if (props.anonymousCount) {
        self.goatote.anonymousCount = props.anonymousCount;
      }
    },
    updateMosaic: (props: SnapshotOut<typeof MosaicModel>) => {
      if (props.downloadsCount) {
        self.mosaic.downloadsCount = props.downloadsCount;
      }
      if (props.usersCount) {
        self.mosaic.usersCount = props.usersCount;
      }
      if (props.pointsCount) {
        self.mosaic.pointsCount = props.pointsCount;
      }
      if (props.redeemedCount) {
        self.mosaic.redeemedCount = props.redeemedCount;
      }
      if (props.bwPlayedCount) {
        self.mosaic.bwPlayedCount = props.bwPlayedCount;
      }
      if (props.ordersCount) {
        self.mosaic.ordersCount = props.ordersCount;
      }
      if (props.ticketsCount) {
        self.mosaic.ticketsCount = props.ticketsCount;
      }
    },
    /* eslint-enable no-param-reassign */
  }))
  .actions(self => ({
    fetchInventoryCount: flow(function* fetchStatusRetailDateCount(
      fromDate: number,
      toDate: number,
    ) {
      const inventoryCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          retailer: 'Target',
          fromDate,
          toDate,
        });
      self.updateGoatote(GoatoteModel.create({inventoryCount}));
      return inventoryCount;
    }),
    fetchBorrowedCount: flow(function* fetchStatusRetailerIdDateRangeCount(
      fromDate: number,
      toDate: number,
    ) {
      const borrowedCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          retailerId: '60f6f2813ba49272509eb619',
          fromDate,
          toDate,
        });

      self.updateGoatote(GoatoteModel.create({borrowedCount}));
      return borrowedCount;
    }),
    fetchRegisteredCount: flow(function* fetchReuseReturnRetailerIdDateRange(
      fromDate: number,
      toDate: number,
    ) {
      const registeredCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          retailerId: '60f6f2813ba49272509eb619',
          fromDate,
          toDate,
        });
      self.updateGoatote(GoatoteModel.create({registeredCount}));
      return registeredCount;
    }),
    fetchReusedCount: flow(function* fetchStatusDateRange(
      fromDate: number,
      toDate: number,
    ) {
      const reusedCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          fromDate,
          toDate,
        });
      self.updateGoatote(GoatoteModel.create({reusedCount}));
      return reusedCount;
    }),
    fetchReturnedCount: flow(function* fetchStatusRetailCount() {
      const returnedCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          retailer: 'Target',
        });
      self.updateGoatote(GoatoteModel.create({returnedCount}));
      return returnedCount;
    }),
    fetchCbReusesCount: flow(function* fetchAnonymousUsersTotalCount() {
      const cbReusesCount: number =
        yield self.environment.api.dashboard.getAnonymousUsersCount({
          filter: 'total',
        });

      self.updateGoatote(GoatoteModel.create({cbReusesCount}));
      return cbReusesCount;
    }),
    fetchAnonymousCount: flow(function* fetchAnonymousUsersUnclaimedCount() {
      const anonymousCount: number =
        yield self.environment.api.dashboard.getAnonymousUsersCount({
          filter: 'unclaimed',
        });
      self.updateGoatote(GoatoteModel.create({anonymousCount}));
      return anonymousCount;
    }),
    fetchDownloadsCount: flow(function* fetchStatusRetailDateCount(
      fromDate: number,
      toDate: number,
    ) {
      const downloadsCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          retailer: 'Target',
          fromDate,
          toDate,
        });
      self.updateMosaic(MosaicModel.create({downloadsCount}));
      return downloadsCount;
    }),
    fetchUsersCount: flow(function* fetchStatusRetailerIdDateRangeCount(
      fromDate: number,
      toDate: number,
    ) {
      const usersCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          retailerId: '60f6f2813ba49272509eb619',
          fromDate,
          toDate,
        });
      self.updateMosaic(MosaicModel.create({usersCount}));
      return usersCount;
    }),
    fetchPointsCount: flow(function* fetchReuseReturnRetailerIdDateRange(
      fromDate: number,
      toDate: number,
    ) {
      const pointsCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          retailerId: '60f6f2813ba49272509eb619',
          fromDate,
          toDate,
        });
      self.updateMosaic(MosaicModel.create({pointsCount}));
      return pointsCount;
    }),
    fetchRedeemedCount: flow(function* fetchStatusDateRange(
      fromDate: number,
      toDate: number,
    ) {
      const redeemedCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          fromDate,
          toDate,
        });
      self.updateMosaic(MosaicModel.create({redeemedCount}));
      return redeemedCount;
    }),
    fetchBwPlayedCount: flow(function* fetchStatusRetailerIdCount() {
      const bwPlayedCount: number =
        yield self.environment.api.dashboard.getStatusRetailerCount({
          status: 'Purchased',
          retailerId: '60f6f2813ba49272509eb619',
        });
      self.updateMosaic(MosaicModel.create({bwPlayedCount}));
      return bwPlayedCount;
    }),
    fetchOrdersCount: flow(function* fetchReuseReturnStatusRetailerIdCount() {
      const ordersCount: number =
        yield self.environment.api.dashboard.getReuseCount({
          status: 'Returned',
          retailerId: '60f6f2813ba49272509eb619',
        });
      self.updateMosaic(MosaicModel.create({ordersCount}));
      return ordersCount;
    }),
    fetchTicketsCount: flow(function* fetchTotalReusesCount() {
      const ticketsCount: number =
        yield self.environment.api.dashboard.getReuseCount({
          status: 'Reuse',
        });
      self.updateMosaic(MosaicModel.create({ticketsCount}));
      return ticketsCount;
    }),
  }));

type DashboardType = Instance<typeof DashboardModel>;
export interface Dashboard extends DashboardType {}
type DashboardSnapshotType = SnapshotOut<typeof DashboardModel>;
export interface DashboardSnapshot extends DashboardSnapshotType {}
export const createDashboardDefaultModel = () =>
  types.optional(DashboardModel, {} as any);
