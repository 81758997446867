import {ReactComponent as DashboardSvg} from '../assets/element-4.svg';
import {ReactComponent as ThingsSvg} from '../assets/story.svg';
import {ReactComponent as PlacesSvg} from '../assets/global-search.svg';
import {ReactComponent as PeopleSvg} from '../assets/profile-2user.svg';
import {ReactComponent as OperationsSvg} from '../assets/share.svg';
import {ReactComponent as AdminSvg} from '../assets/profile-circle.svg';

export const drawerCollapsedWidth = 104;
export const drawerWidth = 280;

export const drawerItems = [
  {
    key: 'dashboard',
    path: '/',
    icon: DashboardSvg,
    name: 'admin.drawer.menu.dashboard',
    active: true,
  },
  {
    key: 'things',
    path: '/things',
    icon: ThingsSvg,
    name: 'admin.drawer.menu.things',
    active: false,
  },
  {
    key: 'places',
    path: '/places',
    icon: PlacesSvg,
    name: 'admin.drawer.menu.places',
    active: false,
  },
  {
    key: 'people',
    path: '/people',
    icon: PeopleSvg,
    name: 'admin.drawer.menu.people',
    active: false,
  },
  {
    key: 'operations',
    path: '/operations',
    icon: OperationsSvg,
    name: 'admin.drawer.menu.operations',
    active: false,
  },
  {
    key: 'admin',
    path: '/admin',
    icon: AdminSvg,
    name: 'admin.drawer.menu.admin',
    active: false,
  },
];
