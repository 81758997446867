import axios, {AxiosInstance} from 'axios';

import {DashboardApi, DashboardApiInterface} from './dashboard/dashboard.api';
import {ApiConfig, DEFAULT_API_CONFIG} from './api.config';

export interface ApiInterface {
  dashboard: DashboardApiInterface;
  setup(): void;
}

export class Api implements ApiInterface {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  instance?: AxiosInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  dashboard: DashboardApiInterface;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.dashboard = new DashboardApi();
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    this.instance = axios.create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {'X-Custom-Header': 'foobar'},
    });
    this.dashboard.setup(this.instance);
  }
}
