import * as React from 'react';
import Box from '@mui/material/Box';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {Text} from '..';
import {ReactComponent as CalenderSvg} from '../../assets/calendar.svg';

import {SelectDateProps} from './selectDate.props';

export function SelectDate(props: SelectDateProps) {
  const {label, value, onChangeDate} = props;
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        open={isOpen}
        label={label}
        value={value}
        showToolbar={false}
        disableCloseOnSelect={false}
        onChange={newValue => {
          if (newValue) {
            onChangeDate(newValue);
            setIsOpen(false);
          }
        }}
        onClose={() => setIsOpen(false)}
        renderInput={({inputRef, inputProps}) => (
          <Box
            ref={inputRef}
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#FFFFFF',
              padding: '10px 20px',
              borderRadius: '24px',
              boxShadow: '5px 5px 5px #00000005',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => setIsOpen(true)}
          >
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Box sx={{display: 'flex', alignItems: 'center', mr: 2}}>
                <CalenderSvg />
              </Box>
              <Text
                {...inputProps}
                variant="body1"
                sx={{
                  color: '#002C58',
                  fontWeight: 'fontWeightSemiBold',
                  userSelect: 'none',
                }}
              >
                {inputProps && inputProps.value}
              </Text>
            </Box>
          </Box>
        )}
      />
    </LocalizationProvider>
  );
}
