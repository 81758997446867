import AppBar from '@mui/material/AppBar';
import {drawerCollapsedWidth, drawerWidth} from '../../config/layout';
import {useSettings} from '../../contexts/SettingsProvider';

import {AppBarProps} from './appBar.props';

export function AdminAppBar(props: AppBarProps) {
  const {children} = props;
  const {collapsed} = useSettings();
  const width = collapsed ? drawerCollapsedWidth : drawerWidth;

  return (
    <AppBar
      position="fixed"
      sx={{
        width: {lg: `calc(100% - ${width}px)`},
        marginLeft: {lg: width},
        backgroundColor: '#FAFCFE',
        height: '75px',
      }}
    >
      {children}
    </AppBar>
  );
}
