import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Text} from '..';

import {OverviewProps} from './overview.props';

export function Overview(props: OverviewProps) {
  const {data, color} = props;

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {data.map(eachData => (
          <Grid key={eachData._id} item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{boxShadow: '5px 5px 5px #00000005', borderRadius: '30px'}}
            >
              <CardContent sx={{display: 'flex', p: 2}}>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: `${color}` + '20',
                      borderRadius: '20px',
                      padding: '20px',
                      mb: '20px',
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h1"
                      sx={{color: color}}
                    >
                      {eachData.value}
                    </Typography>
                  </Box>
                  <Text
                    color="textSecondary"
                    variant="h4"
                    sx={{fontWeight: 'fontWeightMedium'}}
                    tx={eachData.name}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
