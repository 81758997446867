import * as React from 'react';
import Box from '@mui/material/Box';
import {Text, Image, SelectLocation, SelectDate} from '..';

import {DashboardHeaderProps} from './dashboardHeader.props';

export function DashboardHeader(props: DashboardHeaderProps) {
  const {
    imgAlt,
    imgSrc,
    imgHeight,
    showLocation = false,
    fromDate,
    toDate,
    onChangeFromDate,
    onChangeToDate,
    ...rest
  } = props;
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Image imgAlt={imgAlt} imgSrc={imgSrc} imgHeight={imgHeight} />
      <Text variant="h4" sx={{color: '#002C58', ml: 2}} {...rest} />
      <Box sx={{flexGrow: 1}} />
      <Box sx={{px: 2}} />
      {showLocation && (
        <Box sx={{width: '33%'}}>
          <SelectLocation />
        </Box>
      )}
      {showLocation && <Box sx={{px: 2}} />}
      <Box sx={{width: '15%'}}>
        <SelectDate
          label={'From date'}
          value={fromDate}
          onChangeDate={onChangeFromDate}
        />
      </Box>
      <Box sx={{px: 2}}>
        <Text
          variant="body1"
          sx={{color: '#002C58', fontWeight: 'fontWeightMedium'}}
          tx={'common.to'}
        />
      </Box>
      <Box sx={{width: '15%'}}>
        <SelectDate
          label={'To date'}
          value={toDate}
          onChangeDate={onChangeToDate}
        />
      </Box>
    </Box>
  );
}
